import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "../components/container"
import FinalCTA from "../components/final-cta"
import Layout from "../components/layout"
import SmallYellowBlob from "../components/small-yellow-blob"
import SmartLink from "../components/smart-link"
import { FiInstagram } from "react-icons/fi"
import Seo from "../components/seo"
import { Link } from "gatsby"
import Button from "../components/button"

const about = () => {
  return (
    <Layout>
      <article itemScope itemType="http://schema.org/Author">
        <div
          className="border-l-[10px] border-r-[10px] lg:border-l-[60px] lg:border-r-[60px] border-t-[30px] border-b-[30px]  border-lighterPrimary overflow-x-clip"
          style={{ zIndex: 1 }}
        >
          {/* About Taylor */}
          <Container noMax>
            <div className="flex flex-col items-center lg:flex-row justify-between relative mt-12 lg:mt-32 max-w-6xl mx-auto">
              <div
                className="flex-1 relative lg:ml-36 order-last	lg:order-1 "
                style={{ zIndex: 11 }}
              >
                <StaticImage
                  src="../images/IMG_0231-1.jpg"
                  alt="dog training with mia at home in Boise, Idaho"
                  className="rounded-lg"
                  height={500}
                />
                <StaticImage
                  src="../images/cute-scott.png"
                  className=" -bottom-5 -left-2 lg:-left-20 rounded-lg"
                  style={{ position: "absolute" }}
                  alt="Scott and Taylor sitting on their porch when he was first adopted"
                />
              </div>
              <div className="flex-1 order-2 relative px-4">
                <div
                  className="absolute top-[10%] left-[10%] lg:-left-[30%] w-[100%] lg:w-[175%] h-[200%] lg:h-[175%]"
                  style={{ zIndex: 0 }}
                >
                  <SmallYellowBlob />
                </div>
                <div
                  style={{ zIndex: 11 }}
                  className="relative max-w-lg mx-auto mb-12"
                >
                  <h3 className="text-center lg:text-left">About</h3>
                  <h1 className="mb-2 text-center lg:text-left">
                    Taylor Wyllie, KPA CTP
                  </h1>
                  <p className=" font-medium text-sm mb-16 lg:text-md">
                    <SmartLink
                      href={"https://www.instagram.com/scottsschoolfordogs/"}
                    >
                      <span className="text-gray-700 hover:underline flex items-center">
                        <FiInstagram className="inline mr-1" />
                        Instagram
                      </span>
                    </SmartLink>{" "}
                    {/* |{" "}
                    <SmartLink
                      href={
                        "https://www.youtube.com/channel/UCq7JRBOfka2uf0aeH3dSm-A"
                      }
                    >
                      <span className="text-gray-500 hover:underline">
                        Youtube
                      </span>
                    </SmartLink> */}
                  </p>

                  <h2 className="mb-4 text-center lg:text-left">
                    My Qualifications
                  </h2>
                  <p className="mb-4">
                    The Karen Pryor Academy for Animal Training & Behavior is
                    one of the most respected and rigorous professional dog
                    training programs worldwide.
                  </p>
                  <p className="mb-4">
                    This intensive program ensures all of its graduates have a
                    deep understanding of the science of dog training, dog
                    behavior, and teaching methodology.
                  </p>
                  <p className="mb-4">
                    In an industry without professional standards, the KPA CTP
                    is a certification you can trust. I am a proud graduate of
                    the academy.
                  </p>
                </div>
              </div>
            </div>
          </Container>
          <Container>
            <div
              className="text-lightBlack mt-48 max-w-lg mx-auto relative px-4 xl:px-0"
              style={{ zIndex: 11 }}
            >
              <div
                className="hidden md:block  absolute bottom-[20%] -left-[40%] lg:-left-[85%] w-[100%] rotate-[150deg]	 "
                style={{ zIndex: 0 }}
              >
                <SmallYellowBlob />
              </div>
              <div className="relative">
                <h2 className="mb-4 font-semibold">My Dog Training Methods</h2>
                <p className="mb-6">
                  As a graduate of the Karen Pryor Academy and a member of the
                  Association of Professional Dog Trainers, I am committed to
                  using the most up-to-date scientifically-backed dog training
                  methods.
                </p>
                <p className="mb-6">
                  I do not use fear, pain, or intimidation—including the use of
                  choke or prong collars or e-collars—in my training. Instead, I
                  use positive reinforcement-based dog training, sometimes with
                  a clicker.
                </p>
                <p className="mb-12">
                  With this style of dog training, you can enjoy long-lasting
                  results. Plus, everyone in the family, including children and
                  your dog (s), can be an active part of the dog training
                  process.
                </p>
                <div className="mx-auto self-center w-fit mb-12">
                  <Link to="/classes/">
                    <Button>Get Started Training</Button>
                  </Link>
                </div>

                {/* How R+ works */}
                <h2 className="mb-4 font-semibold">
                  How Positive Reinforcement Dog Training Works
                </h2>
                <p className="mb-6">
                  This science-backed dog training methodology means we look for
                  behaviors that we want to be repeated, then we reinforce them
                  with something the animal wants. Like a treat, tug session, or
                  a belly rub!{" "}
                </p>
                <p className="mb-12">
                  This works because research shows that all animals (humans
                  too) are very likely to repeat a behavior that results in an
                  enjoyable consequence. Or — in layman&apos;s terms — animals
                  are likely to repeat actions that lead to good things.
                </p>
                <div className="mx-auto self-center w-fit mb-12">
                  <Link to="/tips/how-to-use-positive-reinforcement-techniques-to-train-your-dog/">
                    <Button>Learn More</Button>
                  </Link>
                </div>

                <h2 className="mb-4 font-semibold">My Story</h2>
                <p className="mb-6 relative">
                  When I adopted an adorable lab/terrier mix from the Humane
                  Society—a dog they described as a “social butterfly” — I had
                  no idea what I was getting myself into. Sure, I grew up with
                  dogs, but their behavior issues had nothing on my new family
                  member. Scott barked at everything from skateboarders to dogs
                  to people to “suspicious” trash. He even barked at me, all day
                  and all night. I didn&apos;t sleep for nearly a month.
                </p>
                <p className="mb-6">
                  I didn&apos;t want to deal with his immense behavioral
                  problems, but I loved him. Beneath his reactivity, I knew
                  there was a good dog. So—after some trial and mostly error
                  with other dog training techniques—I dove into the world of
                  positive reinforcement-based dog training. I was rewarded with
                  amazing amounts of progress. Scott transformed into a happier,
                  calmer dog. He&apos;s still reactive, but his “outbursts” are
                  few and far between.
                </p>
                <p className="mb-12">
                  Inspired by my own success, I founded the{" "}
                  <a
                    href="https://www.meetup.com/boise-reactive-dog-owners/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Boise Reactive Dog Owners group
                  </a>{" "}
                  as a resource for other struggling dog guardians. I also
                  started volunteering with local shelters. Somewhere along the
                  way, I realized that I wanted to help as many people and their
                  dogs as possible — and that meant becoming a professional. So,
                  I applied to the Karen Pryor Academy for Animal Training &
                  Behavior, and, well, the rest is history.
                </p>

                {/* Statement of Inclusion */}
                <h2 className="mb-4 font-semibold">Statement of Inclusion</h2>
                <p className="mb-12">
                  At Scott&apos;s School for Dogs, we respect not only each
                  dog&apos;s unique selves, but we welcome dog guardians of
                  every age, race, gender, ability, neurodivergent status and
                  beyond as well. We promise you will always be treated with
                  kindness and respect. We will always work with you to
                  accomplish your dog training goals
                </p>

                <h2 className="mb-4 font-semibold">
                  Why the name Scott&apos;s School for Dogs?
                </h2>
                <p className="mb-6">
                  Our dogs are always trying to communicate with us; we simply
                  have to learn how to listen. That&apos;s why I consider dogs
                  to be the greatest teachers when it comes to dog training.
                  They can tell us what training techniques are working and what
                  may be hindering the dog training process.
                </p>

                <p className="mb-24">
                  That&apos;s why I named my business Scott&apos;s School for
                  Dogs — while I learned a lot during my time with the Karen
                  Pryor Academy, I consider my dog Scott to be my greatest
                  teacher.
                </p>

                <div className="mx-auto self-center w-fit mb-36">
                  <Link to="/classes/">
                    <Button>Get Started Training</Button>
                  </Link>
                </div>
              </div>
            </div>
          </Container>
          {/* Final CTA */}
        </div>
        {/* Final CTA */}
        <FinalCTA />
      </article>
    </Layout>
  )
}

export const Head = () => <Seo title={"About"} />

export default about
