import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Button from "./button"
import Container from "./container"

const FinalCTA = () => {
  return (
    <Container
      noMax
      style={{
        overflow: "clip",
        minHeight: "800px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div className="absolute -top-[0]]  h-full" style={{ zIndex: 10 }}>
        <StaticImage
          src={"../images/Scott-high-five.jpg"}
          style={{ height: "100%" }}
          transformOptions={{ fit: "cover", cropFocus: "center" }}
          objectFit="cover"
          alt="Scott enjoying the view"
        />
      </div>
      <div
        className="absolute -top-[0]] w-full h-full bg-black opacity-50	"
        style={{ zIndex: 11 }}
      ></div>
      <div
        className="flex flex-col items-center relative  pb-16 px-12 -w-2xl mx-auto text-center"
        style={{ zIndex: 20 }}
      >
        <div
          className="bg-white rounded-full w-32 md:w-56 mb-12"
          style={{ zIndex: 10 }}
        >
          <StaticImage
            src={"../images/round-transparent.png"}
            // width={200}
            layout={"fullWidth"}
            alt="round logo that says Dog training and has an adorable drawing of Scott"
          />
        </div>
        <h4 className="pb-8 text-5xl md:text-7xl text-white font-crimson font-regular md:leading-[3.5rem]">
          Enjoy life <br />
          with your dog
        </h4>
        <Button onClick={() => navigate(`/services`)}>Pick a Service</Button>
      </div>
    </Container>
  )
}

export default FinalCTA
